<div *ngIf="isVisible" class="modal fade show d-block" tabindex="-1" aria-labelledby="addActionModalLabel">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addActionModalLabel">Add an Action</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="actionForm" (ngSubmit)="onSubmit()">

          <!-- First Row: Action Initiator, System, Equipment, Alert Type -->
          <div class="row">
            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">Action Initiator <span class="form-mandatory">*</span>
                  <input type="text" class="form-control" formControlName="actionInitiator"/>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">System
                  <select class="form-select" formControlName="systemType">
                    <option value="">Select System</option>
                    <option *ngFor="let systemType of systemTypes" [value]="systemType">{{systemType}}</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">Equipments
                  <ng-select [multiple]="true" [searchable]="true" (change)="loadAlertTypes()" formControlName="equipmentNames">
                    <ng-option *ngFor="let equipment of equipmentSuggestions; let i = index" [value]="equipment">
                      {{ equipment }}
                    </ng-option>
                  </ng-select>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">Alert Type
                  <select class="form-select" formControlName="alertType">
                    <option value="">-------------------------</option>
                    @for (alertType of alertSuggestions; track $index) {
                      <option [value]="alertType">{{alertType}}</option>
                    }
                  </select>
                </label>
              </div>
            </div>
          </div>

          <!-- Second Row: Action Description & Additional Comment -->
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">Action Description <span class="form-mandatory">*</span></label>
                <textarea maxlength="500" class="form-control" formControlName="description" [ngClass]="{'is-invalid': isInvalidField('description')}"></textarea>
                <div *ngIf="isInvalidField('description')" class="invalid-feedback">
                  Action Description is required.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">Additional Comment</label>
                <textarea maxlength="500" class="form-control" formControlName="comment"></textarea>
              </div>
            </div>
          </div>

          <!-- Third Row: Domain in Charge, Due Date, PQLA Checkbox -->
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label">Domain in Charge</label>
                <select class="form-select" formControlName="domainInCharge">
                  <option value="">Select Domain</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Production">Production</option>
                  <option value="Process">Process</option>
                  <option value="Control Room">Control Room</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-3">
                <label for="estimatedDueDate" class="form-label">Due date of completion (mm/yyyy)</label>
                <input type="month" [min]="minDate" id="estimatedDueDate" class="form-control" formControlName="estimatedDueDate"/>
              </div>
            </div>

            <div class="col-md-4 d-flex align-items-center">
              <div class="mb-3">
                <input class="form-check-input me-2" type="checkbox" value="" id="isPQLA">
                <label class="form-check-label" for="isPQLA">PQLA</label>
              </div>
            </div>
          </div>

          <!-- File Upload -->
          <label class="form-label">
            Upload new supporting files
          </label>
          <div class="upload-file">
            <input
              (change)="selectFile($event)"
              [id]="'upload-input-component'"
              name="fileUpload"
              type="file"
            />
            <label [for]="'upload-input-component'" class="upload-label-bg">
              <span class="material-icons-outlined me-1">insert_drive_file</span>
              <div>
                <span class="d-block lead mb-2">Click here to upload the file <br> or drag’n drop it here.</span>
                <span>Handled formats: .xls, .xlsx, .pdf, .jpg, .png</span>
              </div>
            </label>
            <div *ngIf="file" >
              <h6 class="mt-4 mb-1">File name</h6>
              <ul class="list-unstyled">
                <li class="d-flex justify-content-between align-items-center">
                  <span class="d-flex align-content-center">
                    <span class="material-icons me-2 text-primary" aria-hidden="true">cloud_upload</span> {{ fileName }}
                  </span>
                  <span class="btn btn-link px-0" onKeyDown="resetFile()" (click)="resetFile()">
                    <span class="material-icons">delete</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!actionForm.valid" (click)="onSubmit()">Save</button>
      </div>
    </div>
  </div>
  <!-- Optionally, add a backdrop -->
  <div class="modal-backdrop fade show"></div>
</div>
