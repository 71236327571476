import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionListComponent } from './action-list/action-list.component';
import { NgOptionComponent, NgSelectComponent} from "@ng-select/ng-select";
import { ActionCreationComponent } from "./action-creation/action-creation.component";
import { ActionDeletionComponent } from "./action-deletion/action-deletion.component";

const routes: Routes = [
  { path: '', component: ActionListComponent }
];

@NgModule({
  declarations: [ActionListComponent, ActionCreationComponent, ActionDeletionComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    NgOptionComponent,
    NgSelectComponent,
    ReactiveFormsModule
  ],
  exports: []
})
export class ActionModule { }
