import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from "@ngxs/store";
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { ActionState, FetchActions, UserNameState } from "../../../../statemanagement";
import { GetAlertsData } from '../../../core/state/alerts';
import { ActionResponse, EquipmentsBySystem } from '../../../services/action/model/action.model';
import { ToastService } from "../../../services/toaster/toaster.service";
import { Alert, AlertRules } from '../../../types';
import { Tab } from '../../../types/tab.interface';
import { ActionCreationComponent } from '../action-creation/action-creation.component';
import { ActionDeletionComponent } from "../action-deletion/action-deletion.component";

@Component({
  selector: 'co2-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit, OnDestroy {
  @ViewChild(ActionCreationComponent) actionCreationComponent!: ActionCreationComponent;
  @ViewChild(ActionDeletionComponent) actionDeletionComponent!: ActionDeletionComponent;
  actions$: Observable<ActionResponse[]> = this.store.select(ActionState.getActions);
  userName$: Observable<string> = this.store.select(UserNameState.getUserName);

  date: Date = new Date();

  actions: ActionResponse[] = [];
  tabs: Tab[] = [];
  alerts: Alert[] = [];
  equipments: EquipmentsBySystem[] = [];
  selectedAction!: ActionResponse;

  tabs$: Observable<Tab[]> = this.sb.tabsElements$;
  fpso: string = this.sb.getActiveFpso();
  affiliate: string = this.sb.getActiveAffiliate();

  private destroy$ = new Subject<void>();

  constructor(
    public sb: AppSandbox,
    private store: Store,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    const allAlertTypes = Object.keys(AlertRules).map(key => key.toLowerCase());
    this.store.dispatch(new GetAlertsData(this.affiliate, this.fpso, allAlertTypes, ''));
    combineLatest([this.store.select(state => state.alertData), this.tabs$])
        .pipe(
            takeUntil(this.destroy$),
            filter(([alertData, tabs]) => {
              return alertData && alertData.alerts && tabs && tabs.length > 0;
            })
        )
        .subscribe(([alertData, tabs]) => {
          this.alerts = Object.keys(alertData.alerts).map(key => alertData.alerts[key]);
          this.equipments = tabs[0].systems.map(system => ({
            systemType: system.systemName,
            alertTypesByEquipment: system.equipments.map(equipment => ({
              equipmentName: equipment.equipmentName,
              alertTypes: this.alerts
                .filter(alert =>
                  alert.systemType === system.systemType &&
                  alert.equipmentName === equipment.equipmentName
                )
                .map(alert => alert.alertType)
            }))
          }));
        });

    this.store.dispatch(new FetchActions(this.affiliate, this.fpso));
    this.actions$.pipe(takeUntil(this.destroy$)).subscribe(actions => this.actions = actions);
  }

  onActionCreated(): void {
    this.store.dispatch(new FetchActions(this.affiliate, this.fpso));
  }

  openCreationModal(): void {
    if (this.actionCreationComponent) {
      this.actionCreationComponent.openModal();
    }
  }

  openDeletionModal(action: ActionResponse): void {
    this.userName$.pipe(takeUntil(this.destroy$)).subscribe(userName => {
      if (action.actionInitiator !== userName) {
        this.toastService.showToast('You can only delete your own actions', 'error');
      } else if (action.actionInitiator === userName && this.actionDeletionComponent) {
        this.actionDeletionComponent.openModal();
        this.selectedAction = action;
      }
    });
  }

  closeDeletionModal(): void {
    this.selectedAction = {} as ActionResponse;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
