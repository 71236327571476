import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionInput, EquipmentsBySystem } from '../../../services/action/model/action.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alert } from '../../../types';
import { Store } from "@ngxs/store";
import { CreateAction } from "../../../../statemanagement";

@Component({
  selector: 'co2-action-creation',
  templateUrl: './action-creation.component.html',
  styleUrls: ['./action-creation.component.scss']
})
export class ActionCreationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() equipments: EquipmentsBySystem[] = [];
  @Input() userName: Observable<string> = of('');
  @Output() actionCreated: EventEmitter<void> = new EventEmitter();

  alerts: Alert[] = [];
  isVisible = false;
  actionForm: FormGroup = this.fb.group({});
  isSubmitting: boolean = false;
  equipmentSuggestions: string[] = [];
  alertSuggestions: string[] = [];
  systemTypes: string[] = [];
  minDate = '';
  file: any;
  fileName = '';
  message = '';
  date: Date = new Date();
  fpso: string = this.sb.getActiveFpso();
  affiliate: string = this.sb.getActiveAffiliate();

  private destroy$ = new Subject<void>();

  constructor(
      private fb: FormBuilder,
      public sb: AppSandbox,
      private store: Store
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadSystemTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['equipments']) {
      this.loadSystemTypes();
    }
  }

  openModal(): void {
    this.isVisible = true;
  }

  closeModal(): void {
    this.isVisible = false;
    this.initForm();
  }

  loadSystemTypes(): void {
    this.systemTypes = this.equipments.map(e => e.systemType);
  }

  getCurrentDate(): string {
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}`;
  }

  loadAlertTypes(): void {
    const systemType = this.actionForm.get('systemType')?.value;
    const equipmentNames = this.actionForm.get('equipmentNames')?.value;
    const foundSystem = this.equipments.find(e => e.systemType === systemType);
    if (!foundSystem) {
      this.alertSuggestions = [];
    } else {
      const matchingAlertTypesArrays = foundSystem.alertTypesByEquipment
          .filter(({ equipmentName }) => equipmentNames.includes(equipmentName))
          .map(({ alertTypes }) => alertTypes);

      this.alertSuggestions = equipmentNames.length > 1
          ? []
          : matchingAlertTypesArrays.reduce((acc, curr) =>
              acc.filter(alert => curr.includes(alert)),
          matchingAlertTypesArrays[0] || []
      );
    }
  }

  loadEquipmentsOptions(): void {
    this.actionForm.get('systemType')?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: string) => {
          const equipmentNamesControl = this.actionForm.get('equipmentNames');
          if (!equipmentNamesControl) return;
          equipmentNamesControl.setValue('');
          this.alertSuggestions = [];
          if (!value) {
            equipmentNamesControl.disable();
            this.equipmentSuggestions = [];
          } else {
            equipmentNamesControl.enable();
            const selectedEquipments = this.equipments.find(e => e.systemType === value);
            this.equipmentSuggestions = selectedEquipments?.alertTypesByEquipment
                .filter(e => e.equipmentName !== null)
                .map(e => e.equipmentName) ?? [];
          }
        });
  }

  initForm(): void {
    this.actionForm = this.fb.group({
      actionInitiator: [{ value: '', disabled: true }, [Validators.required]],
      systemType: [''],
      equipmentNames: [{ value: '', disabled: true }],
      alertType: [''],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      comment: ['', [Validators.maxLength(500)]],
      domainInCharge: [''],
      estimatedDueDate: [''],
      isPQLA: [false]
    });
    this.userName.pipe(takeUntil(this.destroy$)).subscribe(userName => this.actionForm.patchValue({ actionInitiator: userName }));
    this.loadEquipmentsOptions();
    this.minDate = this.getCurrentDate();
    this.resetFile();
  }

  isInvalidField(field: string): boolean {
    return !!(this.actionForm.get(field)?.errors && this.isSubmitting);
  }

  selectFile(event: any): void {
    this.file = event.target.files[0];
    this.fileName = this.file?.name;
    this.message = '';
  }

  resetFile(): void {
    const fileInput = document.getElementById('upload-input-component') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.file = '';
    this.fileName = '';
  }

  onSubmit(): void {
    this.isSubmitting = true;
    if (this.actionForm.valid) {
      const actionData: ActionInput = {
        actionInitiator: this.actionForm.get('actionInitiator')?.value,
        systemType: this.actionForm.value.systemType,
        equipmentNames: this.actionForm.value.equipmentNames,
        alertType: this.actionForm.value.alertType,
        description: this.actionForm.value.description,
        comment: this.actionForm.value.comment,
        domainInCharge: this.actionForm.value.domainInCharge,
        estimatedDueDate: this.actionForm.value.estimatedDueDate,
        isPQLA: this.actionForm.value.isPQLA
      };

      this.store.dispatch(new CreateAction(this.affiliate, this.fpso, actionData, this.file)).subscribe({
        complete: () => {
          this.isSubmitting = false;
          this.closeModal();
          this.actionCreated.emit();
        },
        error: () => {
          this.isSubmitting = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
