import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActionResponse } from "../../../services/action/model/action.model";
import { DeleteAction } from "../../../../statemanagement";
import { Store } from "@ngxs/store";


@Component({
  selector: 'co2-action-deletion',
  templateUrl: './action-deletion.component.html'
})
export class ActionDeletionComponent {
  @Input() action: ActionResponse = {} as ActionResponse;
  @Output() closed = new EventEmitter<void>();
  visible: boolean = false;
  isDeleting: boolean = false;

  constructor(private store: Store) {}

  cancel(): void {
    this.visible = false;
    this.closed.emit();
  }

  openModal(): void {
    this.visible = true;
  }

  confirm(): void {
    this.isDeleting = true;
    this.store.dispatch(new DeleteAction(this.action));
    this.closed.emit();
    this.isDeleting = false;
    this.visible = false;
  }
}
