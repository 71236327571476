<div class="card">
    <div class="header">
    <div class="header--item"></div>
    <div class="card-title">Action List</div>
    <div class="subtitle">
    <div class="material-icons-outlined icon">info</div>
</div>
    </div>
    <button type="button" class="add btn btn-secondary position-absolute end-0 me-3" (click)="openCreationModal()"><span class="material-icons">add_circle</span> Add an Action </button>
    <div class="card-body">
        <div class="table">
            <div class="header--row">
                <div class="cell">Date</div>
                <div class="cell">Status</div>
                <div class="action">Action</div>
                <div class="cell">System</div>
                <div class="cell">Equipment</div>
                <div class="cell">Alert Type</div>
                <div class="cell">Domain in charge</div>
                <div class="cell">Due Date</div>
                <div class="cell"></div>
            </div>

            <div class="header--content"  *ngFor="let action of actions">
                <div class="cell">{{action.lastModifiedAt | date:'yyyy-MM-dd'}}</div>
                <div *ngIf="action.status === 'PENDING'" class="cell pending">

                    <span class="material-icons">
                        pending
                        </span>
                        Pending
                </div>
                <div *ngIf="action.status === 'CLOSED'" class="cell closed">

                    <img src="../../../../../../assets/check_circle.svg" alt="check button">
                     Closed</div>
                <div class="action">{{action.description}}</div>
                <div class="cell">{{action.systemType}}</div>
                <div class="cell">{{action.equipmentNames}}</div>
                <div class="cell">{{action.alertType}}</div>
                <div class="cell">{{action.domainInCharge}}</div>
                <div class="cell">{{action.estimatedDueDate}}</div>
                <div class="cell btn">
                    <span class="material-icons-outlined act">
                        fullscreen
                    </span>
                    <span class="material-icons-outlined act">
                      check_circle
                    </span>
                    <button type="button" class="material-icons-outlined act" (click)="openDeletionModal(action)">
                      delete
                    </button>
                </div>
            </div>
        </div>
    </div>
  <co2-action-creation [equipments]="equipments" [userName]="userName$" (actionCreated)="onActionCreated()"></co2-action-creation>
  <co2-action-deletion [action]="selectedAction" (closed)="closeDeletionModal()"></co2-action-deletion>
</div>
